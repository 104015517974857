/* Stylesheet for the 'ICustomTheme' Plone browser layer */
.plone-toolbar-left #edit-zone {
    z-index: 20;
}

#global_statusmessage {
    clear: both
}

#tinymce.mce-content-body,
#content-core {
    a {
        text-decoration: underline;
        &:hover {
            color: #0077d9;
            text-decoration: none;
        }
    }
    ul {
        padding-left: 13px;
        li {
            list-style: unset;
        }
    }
    ol {
        li {
            list-style: unset;
        }
    }
    hr {
        display: inline-block;
        margin-top: 0;
        width: 100%;
    }
}

body {
    color: #666;
    font-size:14px;
    font-weight:400;
    background-color: #fff;
    font-family: 'Poppins', sans-serif;
	margin: 0px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto;
}

.container-fluid {
    max-width: 1200px;
}
.city_top_navigation,
.city_top_navigation.sticky {
    border-bottom: 2px solid @secondary-color;
    padding-left: 35px;
    padding-right: 35px;
    .container-fluid {
        max-width: unset;
    }
}

.accordion-section-title.active,
.banner_text,
.blog_author_date li::before,
.blog_next_post ul li a:hover,
.blog_post_slide .slick-next:hover,
.blog_post_slide .slick-prev:hover,
.blog_tags a:hover,
.city_404_text .theam_btn:hover,
.city_404_wrap.coming.overlay::before,
.city_about_list.list2 .theam_btn:hover,
.city_about_video .overlay::before,
.city_blog2_fig .paly_btn:hover,
.city_blog2_fig.fig2 .theam_btn:hover,
.city_blog2_fig.fig2.detail .city_blog2_met,
.city_blog2_fig.fig2.detail .city_blog2_met::after,
.city_blog2_fig.fig2.detail .city_blog2_met::before,
.city_blog2_fig:hover .city_blog2_met,
.city_blog2_fig:hover .city_blog2_met::after,
.city_blog2_fig:hover .city_blog2_met::before,
.city_blog2_list:hover .city_meta_list,
.city_blog_fig.position:hover .theam_btn,
.city_business_fig .city_service2_list span,
.city_client_wrap,
.city_comment_form_login .theam_btn,
.city_contact_text:hover,
.city_department_fig:hover,
.city_department_wrap .slick-next:hover,
.city_department_wrap .slick-prev:hover,
.city_department_wrap.overlay::before,
.city_emergency_slider .slick-next:hover,
.city_emergency_slider .slick-prev:hover,
.city_event2_date,
.city_event2_fig:hover .theam_btn.btn2,
.city_event2_list2_row:hover .event_categories_date,
.city_event2_list2_row:hover .theam_btn,
.city_event2_year .list li.option.selected.focus,
.city_event2_year .list li:hover,
.city_event_detail.question .event_booking_area .theam_btn.btn2,
.city_event_fig:hover .event_date,
.city_full_event_list:hover .theam_btn.btn2,
.city_health2_text .slick-next:hover,
.city_health2_text .slick-prev:hover,
.city_jobs_item.overlay::before,
.city_main_banner .slick-next:hover,
.city_main_banner .slick-prev:hover,
.city_mayor_contact .theam_btn,
.city_news2_detail:hover .theam_btn,
.city_news2_post.post2:hover .theam_btn,
.city_news2_text .theam_btn,
.city_news_feild,
.city_project_fig .overlay::before,
.city_project_wrap .slick-next:hover,
.city_project_wrap .slick-prev:hover,
.city_service2_btn.btn2 .theam_btn,
.city_special2_dream,
.city_team_fig .city_top_social li a:hover,
.city_top_navigation,
.city_top_news span,
.city_top_social ul li a,
.city_treatment_text .paly_btn,
.city_treatment_wrap,
.city_visit_text .theam_btn:hover,
.event_booking_form .theam_btn:hover,
.event_counter_list .countdown li,
.forum_author_posts,
.forum_title,
.list li.option.selected.focus,
.list li:hover,
.navigation ul li ul li a:hover,
.pagination ul li a:hover,
.side_news_deatil:hover .theam_btn,
.side_submit_form,
.sidebar_search_login a,
.special_service_text .theam_btn:hover,
.widget_wrap,
.widget_wrap.overlay::before,
.city_top_navigation::before {
    background-color: @brand-color;
}

.banner_search_field a, 
.blog_next_post ul li a i, 
.blog_post_slide_text h6:hover a, 
.breadcrumb > li a:hover, 
.categories_list ul li a:hover, 
.city_about_link li a:hover, 
.city_blog2_fig.fig2 .city_blog2_text h4:hover a, 
.city_blog2_fig.fig2:hover .city_meta_list li a i, 
.city_blog2_fig.fig2:hover .city_meta_list li a:hover, 
.city_blog2_fig:hover .see_more_btn, 
.city_blog2_list .city_meta_list li a i, 
.city_blog2_text h5:hover a, 
.city_blog_fig.position:hover .share_icon, 
.city_blog_grid_text a:hover, 
.city_blog_text.event2 .city_meta_list li a:hover, 
.city_blog_text.event2 .social_icon li a:hover, 
.city_blog_text.event2 h4:hover a, 
.city_blog_text.event2 span, 
.city_busine_detail li a i, 
.city_busine_detail li a:hover, 
.city_checkout_list li a:hover, 
.city_department_text a:hover, 
.city_document_list ul li a:hover, 
.city_emergency_call ul li a:hover, 
.city_emergency_slide_text h5:hover a, 
.city_event2_list2_row:hover .share_icon, 
.city_event2_text h4:hover a, 
.city_event2_text span, 
.city_event2_text ul li a:hover, 
.city_event_fig a:hover, 
.city_event_fig:hover .city_date_text a i, 
.city_event_fig:hover .custom_size, 
.city_event_fig > a:hover, 
.city_event_tags span a:hover, 
.city_forget:hover, 
.city_health2_service a:hover, 
.city_health2_service:hover h5 a, 
.city_health2_service:hover span, 
.city_jobs_item span, 
.city_local_link li a:hover, 
.city_mayor_text h2, 
.city_mayor_text p, 
.city_news2_detail .city_meta_list li a:hover, 
.city_news2_detail:hover h2, 
.city_news2_post.post2:hover .city_news2_detail h4, 
.city_news_meta li a:hover, 
.city_office_text a, 
.city_project_description .city_local_link li a::before, 
.city_project_mansory ul li a.active, 
.city_request_link ul li a:hover, 
.city_request_link ul li a:hover::before, 
.city_senior_team:hover .city_senior_team_text h5, 
.city_senior_team_text a:hover, 
.city_service2_list > span, 
.city_service_text h5 a:hover, 
.city_team_fig:hover .city_team_text h4 a, 
.city_visit_text h3 span, 
.city_visit_text p i, 
.ciy_jobs_row span, 
.detail_process_list li a:hover, 
.dl-menuwrapper li a:hover, 
.emergency_service_item h4:hover a, 
.event2_menu_list a:hover, 
.event_categories_text a:hover, 
.event_counter_list .countdown li::before, 
.event_video .paly_btn:hover, 
.forum_author_text h6:hover a, 
.forum_detail_deta .city_meta_list li a:hover, 
.forum_detail_deta a i, 
.forum_detail_deta > a:hover, 
.forum_month a, 
.forum_quote h5, 
.forum_user_meta .city_meta_list li a:hover, 
.information_list li a:hover, 
.navigation ul li ul li a::before, 
.section_heading h2, 
.section_heading h3, 
.see_more_btn:hover, 
.side_contact_text a:hover, 
.side_notice_detail > a, 
.side_notice_text h6:hover a, 
.special_service_text .theam_btn, 
.venue_list ul li p:hover a {
    color: @brand-color;
}

.dl-menuwrapper li a:hover {
    color: #fff;
    background-color: #9abdd6;
}

.breadcrumb > li a:hover {
    color: #fff;
}

.blog_post_slide .slick-next:hover, 
.blog_post_slide .slick-prev:hover, 
.city_about_list.list2 .theam_btn:hover, 
.city_blog2_fig .paly_btn:hover, 
.city_blog2_fig.fig2 .theam_btn:hover, 
.city_blog2_fig:hover .see_more_btn, 
.city_blog2_list:hover .city_blog2_text, 
.city_blog2_list:hover .city_meta_list, 
.city_blog_fig.position:hover .theam_btn, 
.city_business_fig figure::after, 
.city_comment_form_login .theam_btn, 
.city_department_fig:hover, 
.city_department_wrap .slick-next:hover, 
.city_department_wrap .slick-prev:hover, 
.city_emergency_slider .slick-next:hover, 
.city_emergency_slider .slick-prev:hover, 
.city_event2_list2_row:hover .city_event2_list2_fig figure, 
.city_event2_list2_row:hover .theam_btn, 
.city_event2_year .list, 
.city_event2_year .sidebar_search input:focus, 
.city_health2_service:hover, 
.city_health2_text .slick-next:hover, 
.city_health2_text .slick-prev:hover, 
.city_health_fig::after, 
.city_login h4::before, 
.city_news2_detail:hover, 
.city_news2_detail:hover .theam_btn, 
.city_news2_text .theam_btn, 
.city_office_list, 
.city_project_wrap .slick-next:hover, 
.city_project_wrap .slick-prev:hover, 
.city_request_list, 
.city_senior_team > figure, 
.city_service2_btn.btn2 .theam_btn, 
.city_team_fig .city_top_social li a:hover, 
.city_top_social ul li a, 
.city_top_social ul li a:hover, 
.city_visit_text .theam_btn:hover, 
.event_booking_area textarea:focus, 
.event_booking_field input:focus, 
.event_heading, 
.forum_card, 
.forum_quote, 
.navigation ul > li > ul, 
.project_title, 
.section_heading, 
.section_heading.center::before, 
.side_news_deatil:hover .theam_btn, 
.sidebar_heading, 
.sidebar_search input:focus, 
.special_service_text .theam_btn:hover {
    border-color: @secondary-color;
}

.city_health_fig::after {
    pointer-events: none;
}

h1 {
    font-size:50px;
}

h2 {
    font-size: 40px;
}

h3 {
    font-size: 30px;
}

h4 {
    font-size: 26px;
}

h5 {
    font-size: 20px;
}
h6 {
    font-size: 18px;
}

a,
p a {
    color: @brand-color;
    &:hover,
    &:focus,
    &:visited {
        color: @brand-color;
    }
}

.city_top_navigation,
.city_top_navigation.sticky {
    padding-bottom: 10px;
    padding-top: 10px;
    .navigation {
        margin-top: 40px;
    }
    .city_top_form {
        margin-top: 8px;
        @media screen and (min-width: 1200px) {
            margin-top: 38px;
        }
    }
}

.portal-logo {
    align-items: center;
    display: flex;
    h1 {
        margin-bottom: 0;
    }
    a:hover {
        border-bottom: none;
    }
    .logo {
        margin-right: 10px;
        width: 120px;
        @media screen and (max-width: 767px) {
            width: 60px;
        }
    }
    .logo-mayor-name {
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        line-height: 20px;
    }
    .logo-mayor-title {
        color: #fff;
        font-family: "Poppins Italic", sans-serif;
        font-size: 18px;
        font-style: italic;
        font-weight: normal;
    }
}

i.fa {
    .city_top_social &,
    .city_jobs_list & {
        line-height: 2.2em !important;
    }
}

/** Navigation Bar Start **/
.plone-nav {
    > li > a {
        padding: 0;
        &:hover {
            padding-bottom: 30px;
            background-color: transparent;
        }
    }
}
.plone-navbar-nav {
    display: flex;
    margin: 0;
    li .submenu {
        background-color: #fff;
        border-left: none;
        border-radius: 0;
        border-right: none;
        display: block;
        padding-top: 0;
        a {
            color: #666;
            padding: 8px 30px;
            &:hover {
                color: #fff;
            }
        }
    }
    li:last-child .submenu li:hover ul {
        left: 100%;
        top: 0;
    }
}
.navigation > ul > li {
    margin-left: 25px;
    margin-right: 25px;
    flex: 1 0 auto;
    &:first-of-type {
        margin-left: 0;
    }
    & > a {
        font-size: 19px;
    }
}
.dropdown-menu {
    > li > a {
        white-space: normal;
    }
    margin: 0;
}
/** Navigation Bar End **/



.main-banner-slider {
    .social-links {
        float: right;
        font-size: 24px;
        a {
            color: #ffffff;
        }
    }
    .banner_text {
        border: 1px solid @secondary-color;
        box-shadow: 5px 5px 0 @secondary-color;
        margin-top: -165px;
        padding: 30px;
        width: 365px;
        .large_text {
            font-size: 43px;
        }
    }
    .banner_btn {
        margin: 20px 0;
    }
    .theam_btn {
        &:hover {
            background-color: #fff;
            border-color: #fff;
            color: @brand-color !important;
            text-decoration: none;
        }
        &:visited {
            color: #fff;
        }
    }
}
.banner_search_field {
    input {
        color: #fff;
        width: inherit;
        &:focus {
            border-color: #fff;
        }
        &:focus::-webkit-input-placeholder { color:transparent; }
        &:focus:-moz-placeholder { color:transparent; } /* Firefox 18- */
        &:focus::-moz-placeholder { color:transparent; } /* Firefox 19+ */
        &::-ms-input-placeholder { color:#fff; } /* OldEdge ;) */
        &:focus:-ms-input-placeholder { color:transparent; } /* oldIE ;) */
    }
    button {
        padding: 0;
        border: none;
        border-radius: 0;
        position: absolute;
        right: 2px;
        bottom: 0;
        left: auto;
        width: 57px;
        text-align: center;
        line-height: 48px;
        background-color: #fff;
        font-size: 13px;
        height: 48px;
        top: 2px;
        &:hover {
            background-color: transparent;
            box-shadow: none;
            color: #fff;
        }
    }
    .fa-search {
        line-height: 2.2em !important;
    }
}
.city_banner_services {
    margin-bottom: 30px;
    .city_service_text {
        padding: 0 15px;
        @media screen and (max-width: 1199px) {
            span {
                margin-right: 0;
            }
        }
        span {
            margin-right: 5px;
        }
        h5 {
            font-size: 18px;
        }
    }
    .city_service_list ul li {
        @media screen and (max-width: 480px) {
            width: 100%;
        }
        &:last-child {
            display: inline-block;
        }
    }
    .three11-center-icon {
        background-image: url("++theme++terrehaute.theme/static/img/311-center-icon.png");
        background-size: cover;
        height: 32px;
        position: relative;
        top: 2px;
        width: 38px;
    }
}
.home-events {
    clear: left;
}
.city_event_date {
    background-color: @brand-color;
    clear: left;
    .event_date {
        background-color: transparent;
        text-transform: uppercase;
        strong {
            font-size: 18px;
        }
        span {
            font-size: 30px;
        }
        @media screen and (max-width: 480px) {
            margin-bottom: 0;
            strong,
            span {
                display: inline;
                font-size: 20px;
            }
            span:after {
                content: ", ";
            }
        }
    }
    .city_date_text {
        padding-left: 0;
    }
}
.city_event_text {
    background-color: #ffffff;
    margin-bottom: 15px;
}
.city_event_location {
    margin-left: 20px;
}
.city_news p {
    color: #333;
    font-weight: 400;
    line-height: 1.2;
    margin: 0 0 12px;
    font-family: Montserrat,sans-serif;
    a {
        color: #333;
    }
}
.city_department_fig .box img {
    max-width: 115px;
    @media screen and (max-width: 767px) {
        max-width: unset;
        width: 100%;
    }
}
.city_department_fig::after,
.city_jobs_wrap::after {
    content: ' ';
    display: block;
    clear: both;
    height: 0;
}
@media screen and (max-width: 767px) {
    .city_department_fig {
        &:hover {
            background-color: #fff;
            .city_department_text a {
                color: @brand-color;
            }
            .city_department_text h5 {
                color: #000;
            }
            .city_department_text p {
                color: #666;
            }
            .box {
                &::before,
                &::after {
                    display: none;
                }
                img {
                    transform: none;
                }
            }
        }
        .box-layer {
            display: none;
        }
    }

}


.city_jobs_wrap {
    background: none #323232;
    margin-bottom: 30px;
}
.city_jobs_fig {
    width: 40%;
    overflow: hidden;
    max-height: 651px;
    .city_jobs_img {
        width: 100%;
    }
    .city_job_text {
        display: block;
        width: calc(40% - 80px);
        left: 40px;
        bottom: 40px;
        .theam_btn {
            &:visited {
                color: #fff;
            }
            &:hover {
                background-color: #fff;
                border: 2px solid #fff;
                color: @brand-color;
                text-decoration: none;
            }
        }
    }
}
.city_jobs_list {
    width: 60%;
    ul {
        margin: 0;
    }
}
@media screen and (max-width: 767px) {
    .city_jobs_wrap {
        background-color: #202020;
        &::before {
            display: none;
        }
    }
    .city_jobs_fig {
        float: none;
        width: 100%;
        .city_jobs_img {
            float: left;
            width: 33%;
        }
        .city_job_text {
            float: right;
            padding: 10px 10px 20px;
            position: static;
            width: 67%;
        }
    }
    .city_jobs_list {
        float: none;
        width: 100%
    }
}
@media screen and (max-width: 360px) {
    .city_jobs_fig {
        max-height: none;
        .city_jobs_img {
            float: none;
            width: 100%;
        }
        .city_job_text {
            float: none;
            width: 100%;
        }
    }
}


.home-events .section_heading h2 a,
.home-news .section_heading h2 a {
    color: @brand-color;

    &:hover {
        border-bottom: none;
    }
}

.city_department_wrap .section_heading {
    margin-left: 0;
}

.city_jobs_item {
    background-color: @brand-color;
    border-bottom-width: 2px;
    border-left-width: 0;
    border-right-width: 0;
    border-style: solid;
    border-top-width: 2px;
    border-color: @secondary-color;
    color: #fff;
    .fa {
        color: @brand-color;

    }
    li:nth-of-type(1) & {
        border-top-width: 4px;
    }
    li:nth-of-type(3) & {
        border-bottom-width: 4px;
    }
    span {
        color: #fff;
        @media screen and (max-width: 600px) {
            float: none;
            margin-bottom: 20px;
        }
    }
    a,
    a:hover {
        color: #fff;
        text-decoration: none;
    }
    &.inverse {
        background: #fff;
        span {
            border: 4px solid @brand-color;
            color: @brand-color;
        }
        &:hover {
            text-decoration: none;
        }
        .ciy_jobs_caption {
            h2,
            a,
            p {
                color: @brand-color;
            }
        }
    }
    .ciy_jobs_caption {
        @media screen and (max-width: 600px) {
            padding-left: 0;
        }
    }
    @media screen and (max-width: 600px) {
        text-align: center;
    }
}

.city_service_tabs_text {
    padding-top: 0;
    margin-bottom: 33px;
}

/** Fix Plone Forms Start **/
label .label {
    color: #3d3d3d;
    font-size: 1em;
    line-height: 1.4em;
    padding: 0;
}

input,
textarea,
#content-core input,
#content-core textarea {
    height: auto;
    width: auto;
}

/** Fix Plone Forms End **/

/** Footer Start **/
.city_request_row {
    .fa {
        color: @brand-color;
    }
    .city_request_text {
        span,
        h4 {
            color: @brand-color;
        }
    }
}
#global-footer {
    .overlay {
        display: block;
        height: auto;
        margin: -95px 0 0;
        padding-top: 120px;
        width: 100%;
    }
    .find-us {
        display: flex;
        .widget_list {
            float: none;
            padding-right: 20px;
            &:last-of-type {
                padding-right: 0;
            }
            &:first-of-type {
                flex: 0 0 150px;
            }
        }
    }
    .widget_copyright {
        border-top: none;
        margin-top: 0;
        padding-top: 20px;
    }
    .widget_copyright .city_top_social ul li {
        display: inline-block;
    }
    .copyright_text {
        display: block;
        padding-top: 22px;
        text-align: left;
        @media screen and (max-width: 480px) {
            margin-bottom: 1em;
            text-align: center;
        }
        p {
            font-size: 11px;
        }
    }
    .city_top_social {
        @media screen and (max-width: 480px) {
            float: none;
        }
    }
}
.google-translate {
    margin-top: 50px;
    p,
    .goog-te-gadget {
      color: #fff;
    }
    p {
        font-size: 13px;
        margin-bottom: 0;
    }
    .goog-logo-link,
    .goog-logo-link:hover,
    .goog-logo-link:visited {
      color: #fff;
    }
    .goog-te-combo {
        height: auto;
    }
}
/** Footer End **/

/** SAB Banner Start **/
@media screen and (max-width: 480px) {
    .sab_banner {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .sab_banner_text h2 {
        font-size: 28px;
    }
}
.sab_banner.overlay {
    background-color: @brand-color;
    width: 100%;
    display: block;
    margin: 0;
    &::before {
        content: none;
    }
}

.breadcrumb {
    line-height: 1.8em;
}

.breadcrumb > li {
    padding-left: 0;
    margin-right: 18px;
}
/** SAB Banner End **/

/** Document Start **/
.city_health_wrap img {
    max-height: 179px;
}
/** Document End **/

/** Navigation Portlet **/
.city_service_tabs.tabs {
    .tab-links {
        margin-bottom: 0;
    }
    a {
        &:hover,
        &:active {
            text-decoration: none;
        }
    }
}
/** Navigation Portlet End **/

/** Grid View Start **/
.city_service2_fig {
    &:hover {
        figure img {
            transform: none;
        }
        .overlay::before {
            background: @brand-color;
        }
    }
    .overlay {
        img {
            height: auto;
        }
        &::before {
            background: @brand-color;
            opacity: 0.8;
        }
        &.no-image {
            padding-bottom: 39.18%;
            &::before {
                opacity: 1;
            }
        }
    }
    .city_service2_icon {
        display: none;
    }
    .city_service2_caption {
        padding-left: 0;
    }
}
/** Grid View End **/

/** Event View Start **/
.template-event_view {
    .wrapper {
        background-color: #e7e7e7;
    }
    .city_event2_wrap {
        padding-bottom: 0;
    }
    .event_heading {
        line-height: 36px;
    }
}
/** Event View Start **/

/** Event Listing Start **/
.template-event_listing {
    .wrapper {
        background-color: #e7e7e7;
    }
    .city_department_list, .city_event2_list2 > ul > li {
        list-style: none !important;
        margin-bottom: 120px;
    }
    .city_event2_list2_row {
        max-width: 850px;
        left: 50%;
        transform: translateX(-50%);
    }
    .city_event2_list2_fig {
        @media screen and (min-width: 768px) {
            .box {
                max-width: 269px;
                overflow: hidden;
                img {
                    position: relative;
                    max-width: max-content;
                    transform-origin: 134px 50%;
                    width: auto;
                }
            }
        }
    }
    .theam_btn:visited {
        color: #fff;
    }
    .city_blog_icon_list {
        span {
            padding-right: 0;
            &::before {
                content: none;
            }
        }
    }
}
#content-core .city_event2_list2_row ul {
    padding-left: 0;
    li {
        list-style: none;
    }
}


/** Event Listing End **/

/** News Detail Start **/
.template-newsitem_view {
    .wrapper {
        background-color: #e7e7e7;
    }
    .event_detail_text {
        p {
            text-align: left;
        }
        img {
            height: auto;
        }
    }
}
/** News Detail End **/

/** News Listing Start **/
.template-news_listing {
    .wrapper {
        background-color: #e7e7e7;
    }
    .city_news2_post {
        &.post2 figure img {
            height: auto;
        }
        @media screen and (min-width: 768px) {
            .box {
                max-width: 268px;
                overflow: hidden;
                img {
                    position: relative;
                    max-width: max-content;
                    transform-origin: 134px 50%;
                    width: auto;
                }
            }
        }
    }
}
/** News Listing End **/

/** Search Box Start **/
.coth-utils {
    @media screen and (min-width: 1200px) {
        margin-left: 20%;
        width: 75%;
    }
}
.city_top_search {
    [name="SearchableText"] {
        border-bottom: 1px solid #fff;
        display: inline-block;
        font-size: 19px;
        padding: 0;
        width: 83%;
        &::-webkit-input-placeholder { font-style:italic; }
        &:-moz-placeholder { font-style:italic; } /* Firefox 18- */
        &::-moz-placeholder { font-style:italic; } /* Firefox 19+ */
        &::-ms-input-placeholder { color:#fff; } /* OldEdge ;) */
        &:-ms-input-placeholder { font-style:italic; } /* oldIE ;) */
        &:focus::-webkit-input-placeholder { color:transparent; }
        &:focus:-moz-placeholder { color:transparent; } /* Firefox 18- */
        &:focus::-moz-placeholder { color:transparent; } /* Firefox 19+ */
        &:focus:-ms-input-placeholder { color:transparent; } /* oldIE ;) */
    }
    button {
        background: transparent;
        border: none;
        color: #fff;
        display: inline-block;
        padding: 0;
        width: 13px;
        &:hover {
            background: transparent;
            box-shadow: none;
            color: #fff;
        }
    }
}
.top_user {
    color: #fff !important;
    position: relative;
    right: 13px;
    top: 4px;
    width: 10px;
    &:hover {
        color: #fff !important;
    }
}
/** Search Box End **/

/** Search Results Start **/
.template-search {
    #searchform {
        margin-top: 50px;
        .input-group {
            @media screen and (max-width: 790px) {
                display: block;
                max-width: 100%;
            }
        }
        .searchPage {
            width: 100%;
            @media screen and (max-width: 790px) {
                float: none;
                margin-bottom: 1em;
            }
        }
        .actionMenu {
            left: 0;
            @media screen and (max-width: 790px) {
                float: none;
                position: relative;
                dt {
                    position: relative;
                    right: auto;
                    top: auto;
                }
                dd {
                    left: 0;
                    margin-left: 0;
                    right: unset;
                    top: 36px;
                }
            }
        }
    }
    .search-date-options > div,
    .search-type-options > div {
        display: block;
    }
    .documentFirstHeading {
        font-size: 3em;
        margin-top: 20px;
    }
    #search-results-wrapper {
        margin-bottom: 60px;
        margin-top: 41px;
    }
    #rss-subscription a {
        color: #007bb1
    }
    #search-results {
        padding-top: 1em;
        p {
            float: none;
        }
    }
}

/** Search Results End **/

/** Error Page Start **/
body.template-default_error_message,
body.template-login_success {
    .documentFirstHeading {
        font-size: 3em;
        margin-top: 50px;
    }
    h2 {
        font-size: 2em;
    }
    #content-core p {
        white-space: normal;
    }
    #page-not-found-list {
        padding-left: 20px;
        li {
            list-style-type: disc;
            margin-bottom: 10px;
        }
        a:before {
            content: none;
        }
        .discreet {
            display: block;
        }
    }
}
/** Error Page End **/

/** Login/Logout Form Start **/
body.template-login_form #content-core,
body.template-logged_out #content-core {
    margin-top: 50px;
}
/** Login/Logout Form End **/

/** Pagination Navigation Start **/
#content-core .pagination ul {
    padding-left: 0;
}

.pagination ul li {
    &.full-batch-nav {
        display: none;
    }
    @media screen and (min-width: 700px) {
        &.full-batch-nav {
            display: inline-block;
        }
    }
    list-style: none;
    span {
        display: inline-block;
        height: 60px;
        line-height: 60px;
        padding: 0;
        cursor: default;
        width: 60px;
    }
    &.active > span {
        background-color: #00447c;
        border: 1px solid #00447c !important;
        transition: all .3s ease-in-out;
    }
    a {
        cursor: pointer;
        line-height: 60px;
        padding: 0;
    }
    .fa {
        line-height: 60px;
    }
    @media screen and (max-width: 480px) {
        span {
            display: inline-block;
            height: 40px;
            line-height: 40px;
            width: 40px;
        }
        a {
            line-height: 40px;
        }
        .fa {
            line-height: 40px;
        }
    }
}
/** Pagination Navigation End **/
